* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
}

ul {
  outline: none;
}

body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  background: #FAFAFA;
}
